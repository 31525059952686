<template>
	<div id="Welcome">
		<div class="banner">
			<el-image class="background" :src="banner" fit='cover'></el-image>
			<b class="mask"></b>
			<div class="main">
				<div class="cont">
					<p>欢迎辞</p>
					<b>项目周期：2023年3月-2023年12月</b>
					<b>主办单位：北京生命绿洲公益服务中心</b>
					<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" alt=""></b>
				</div>
			</div>
		</div>
		<div class="document">
			<video autoplay controls="controls" preload="auto" loop :src="vedio"></video>
		</div>
	</div>
</template>

<script>
	import {
		getConfig, // 首页获取logo banner 流程图  
		getWelcomeVedio
	} from '@/request/api.js';
	export default {
		name: 'Welcome',
		components: {},
		data() {
			return {
				banner: '',
				vedio: ""
			}
		},
		created() {
			this.getConfigFun();
			this.getWelcomeVedioFun();
		},
		mounted() {},
		methods: {
			getWelcomeVedioFun() {
				getWelcomeVedio().then(res => {
					if (res.code == 200) {
						this.vedio = res.data.vedioPath
					}
				})
			},

			// 首页获取logo banner 流程图
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.banner = res.data.bannerPath;
					}
				})
			}
		},

	}
</script>

<style lang="scss" scoped>
	#Welcome {
		display: flex;
		flex-direction: column;

		.banner {
			min-height: 60vh;
			position: relative;

			.webHander {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 1;
			}

			.background {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 0;
			}

			.mask {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
			}

			.main {
				position: absolute;
				width: 1200px;
				top: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				padding-bottom: 150px;

				.cont {
					display: flex;
					flex-direction: column;

					p {
						font-size: 70px;
						font-weight: 600;
						line-height: 1.5;
					}

					b {
						font-size: 16px;
						font-weight: normal;
						margin-top: 12px;
						color: #000;
						opacity: .6;
						line-height: 1.5;

						img {
							height: 36px;
						}
					}
				}
			}
		}

		.document {
			width: 1200px;
			margin: auto;

			video {
				display: block;
				width: 100%;
				height: auto;
				background-color: #999;
			}
		}
	}
</style>